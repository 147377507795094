"use strict";

import axios from "axios";
import store from "../store";
import { Message } from 'element-ui';
import router from "../router";

const env = require("../../env");
axios.defaults.baseURL = env[process.env.NODE_ENV].BASE_URL;
console.log(axios.defaults.baseURL);

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
let config = {
  timeout: 60 * 1000, // Timeout
  withCredentials: false, // Check cross-site Access-Control
};
const _axios = axios.create(config);
_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    if (store.getters.getToken != null) {
      config.headers["token"] = store.getters.getToken;
    }
    
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    if (response.status === 200 && response.data.success == false) {
      // 警告通知
      if (response.data.errMsg !== "") {
        Message.error(response.data.errMsg);
        if (response.data.errCode == 'auth_0012' || response.data.errCode == 'auth_0011') {
          router.replace({
            path: "/login",
          })
        }
      }
    }
    return response;
  },
  function (error) {
    Message.error(error);
    return Promise.reject(error);
  }
);

export default _axios;
