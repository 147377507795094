import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({

  state: {
    //这里放全局参数
    token: null,
    userInfo: {},
  },

  mutations: {
    //这里是set方法
    setToken(state, token) {
      state.token = token
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
    },
    //清空state
    clear(state){
      sessionStorage.clear()
      state.token = null
      state.userInfo = {}
    }
  },

  getters: {
    //get方法
    getToken: state => state.token,
    getUserInfo: state => state.userInfo,
  },

  actions: {},
  modules: {},
  plugins: [
		createPersistedState({
			storage: window.localStorage,
		}),
	],
})