import _axios from "./axios";
export default class Http {
  static download(url, params) {
    return new Promise((resolve, reject) => {
      _axios
        .get(
          url,
          params,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          },
          { responseType: "arraybuffer" },
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  static post(url, params = {}) {
    return new Promise((resolve, reject) => {
      _axios
        .post(url, params, {
          headers: {
            "Content-Type": "application/json",
          }
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  static get(url, params = {}) {
    return new Promise((resolve, reject) => {
      _axios
        .get(url, params, {
          headers: {
            "Content-Type": "application/json",
          }
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}