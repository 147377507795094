// dev:开发环境 | test:测试环境  | prod:开发环境
module.exports = {
  development: {
    // BASE_URL: "http://192.168.102.13:10100/" /*服务器请求路径*/,
    BASE_URL: "https://linli-api.beipostudio.com/",
    // BASE_URL: "https://api-headhunting.bolecy.com/",
    AMAP_KEY: "",
    AMAP_SECURE_CODE: ""
  },
  test: {
    BASE_URL: "https://linli-api.beipostudio.com/" /*服务器请求路径*/,
    AMAP_KEY: "",
    AMAP_SECURE_CODE: ""
  },
  production: {
    BASE_URL: "https://linli-api.beipostudio.com/" /*服务器请求路径*/,
    AMAP_KEY: "",
    AMAP_SECURE_CODE: ""
  },
};
