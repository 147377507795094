import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from "./store";
import $http from "./request/http"

Vue.config.productionTip = false
Vue.prototype.$http = $http;
Vue.prototype.$store = store;
Vue.use(ElementUI);
// Vue.use(Element, { size: 'small', zIndex: 3000 });

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

new Vue({
  el: '#app',
  router,
  render: h => h(App),
}).$mount('#app')
