import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: () => import('../views/home.vue'),
    children: [
      {
        path: '/', // 嵌套路由里默认是哪个网页
        name: 'welcome',
        component: () => import('../views/welcome'),
      },
      {
        path: '/field',
        component: () => import('../views/field/index.vue'),
      },
      {
        path: '/field/add',
        component: () => import('../views/field/add.vue'),
      },
      {
        path: '/field/detail',
        component: () => import('../views/field/detail.vue'),
      },
      {
        path: '/field/posts/add',
        component: () => import('../views/field/add-posts.vue'),
      },
      {
        path: '/course',
        component: () => import('../views/course/index.vue'),
      },
      {
        path: '/course/add',
        component: () => import('../views/course/add.vue'),
      },
      {
        path: '/course/detail',
        component: () => import('../views/course/detail.vue'),
      },
      {
        path: '/order',
        component: () => import('../views/order/index.vue'),
      },
      {
        path: '/order/detail',
        component: () => import('../views/order/detail.vue'),
      },
      {
        path: '/wx/order',
        component:() => import('../views/wx-shop-mini/index.vue')
      },
      {
        path: '/spu',
        component: () => import('../views/spu/index.vue'),
      },
      {
        path: '/spu/detail',
        component: () => import('../views/spu/detail.vue'),
      },
      {
        path: '/spu/add',
        component: () => import('../views/spu/add.vue'),
      },
      {
        path: '/file',
        component: () => import('../views/file/index.vue'),
      },
      {
        path: '/file/add',
        component: () => import('../views/file/add.vue'),
      },
      {
        path: '/file/edit',
        component: () => import('../views/file/edit.vue'),
      },
      {
        path: '/user',
        component: () => import('../views/user/index.vue'),
      },
      {
        path: '/credit/rule',
        component: () => import('../views/credit-rule/index.vue'),
      },
      {
        path: '/spu/points',
        component: () => import('../views/spu-points-goods/index.vue'),
      },
      {
        path: '/spu/points/add',
        component: () => import('../views/spu-points-goods/add.vue'),
      },
      {
        path: '/spu/points/detail',
        component: () => import('../views/spu-points-goods/detail.vue'),
      },
      {
        path: '/exchange/goods',
        component: () => import('../views/exchange-goods/index.vue'),
      },
      {
        path: '/exchange/goods/add',
        component: () => import('../views/exchange-goods/add.vue'),
      },
      {
        path: '/exchange/goods/detail',
        component: () => import('../views/exchange-goods/detail.vue'),
      },
      {
        path: '/exchange/record',
        component: () => import('../views/exchange-record/index.vue'),
      },
    ],
  },
  {
    path: '/login',
    component: () => import('../views/login.vue'),
  },
  {
    path: '/404',
    component: () => import('../views/404.vue'),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
